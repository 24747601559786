import React, { FC } from 'react';
import Link from 'next/link';
import { Wrap } from './styled';
import { Icon } from '@components/Icon';

interface Props {
  href: string;
}
export const ReadMoreLinkContent: FC = () => {
  return (
    <Wrap>
      Читать дальше <Icon name="cheveron-right.outline" />
    </Wrap>
  );
};

export const ReadMoreLInk: FC<Props> = ({ href }) => {
  return (
    <Link href={href} passHref>
      <a>
        <ReadMoreLinkContent />
      </a>
    </Link>
  );
};
