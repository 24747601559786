import { useMutation, useQuery } from 'react-query';
import {
  addNewsCategory,
  deleteNewsCategory,
  deleteNewsArticle,
  getNewsArticleApi,
  getNewsCategories,
  getNewsListApi,
  saveNewsPage,
} from '@http/endpoints/news';
import { NewsPageRecordsQuery } from '@http/models/api/news';
import { deleteFile, uploadFile } from '@http/endpoints/file';
import { FileUploadResponse } from '@http/models/api/file';

interface UseSaveNewsPage {
  successHandler: (response: number) => void;
  errorHandler: (e: any) => void;
}

export const useSaveNewsPage = ({ successHandler, errorHandler }: UseSaveNewsPage) =>
  useMutation(saveNewsPage, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

interface UseDeletNewsPage {
  successHandler: () => void;
  errorHandler: (e: any) => void;
}

export const useDeletNewsPage = ({ successHandler, errorHandler }: UseDeletNewsPage) =>
  useMutation(deleteNewsArticle, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

export const useNewsListApi = (query: NewsPageRecordsQuery) => {
  return useQuery(
    ['news-list-api', query],
    () => {
      return getNewsListApi(query);
    },
    {
      staleTime: 30 * 1000,
    }
  );
};

export const useNewsArticleApi = (slug: string, enabled: boolean = true) => {
  return useQuery(
    ['news-page-api', slug],
    () => {
      return getNewsArticleApi(slug);
    },
    {
      staleTime: Infinity,
      enabled,
    }
  );
};

export const useNewsCategories = () => {
  return useQuery(
    ['news-category'],
    () => {
      return getNewsCategories();
    },
    {
      staleTime: Infinity,
    }
  );
};

export const useAddNewsCategory = ({
  successHandler,
  errorHandler,
}: {
  successHandler: (res: any) => void;
  errorHandler: (e: unknown) => void;
}) =>
  useMutation(addNewsCategory, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

export const useNewsAddFile = ({
  successHandler,
  errorHandler,
}: {
  successHandler: (res: FileUploadResponse) => void;
  errorHandler: (e: unknown) => void;
}) =>
  useMutation(uploadFile, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

export const useNewsDeleteFile = ({
  successHandler,
  errorHandler,
}: {
  successHandler: () => void;
  errorHandler: (e: unknown) => void;
}) =>
  useMutation(deleteFile, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

export const useDeleteNewsCategory = ({
  successHandler,
  errorHandler,
}: {
  successHandler: (res: any) => void;
  errorHandler: (e: unknown) => void;
}) =>
  useMutation(deleteNewsCategory, {
    onSuccess: successHandler,
    onError: errorHandler,
  });
