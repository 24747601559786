import { rgba } from 'polished';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  margin-top: ${({ theme }) => theme.spaces.xs};
`;

export const SliderWrapper = styled.div`
  max-width: 100%;
  position: relative;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface ArrowProps {
  variant: 'left' | 'right';
  isDisabled: boolean;
}

export const SliderArrow = styled.div<ArrowProps>`
  position: absolute;
  top: calc(50% - 20px);
  z-index: 10;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: ${({ theme }) => theme.shadow.base};
  transition: opacity 0.1s ${({ theme }) => theme.animate.base};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.palette.grey.white};
  color: ${({ theme }) => theme.palette.main.primary};
  opacity: 0.9;
  border: 1px solid transparent;

  &:hover {
    opacity: 1;
    cursor: pointer;
    border-color: ${({ theme }) => theme.palette.main.primary};
  }

  ${({ variant }) => {
    if (variant === 'left') {
      return css`
        left: -10px;
      `;
    }

    if (variant === 'right') {
      return css`
        right: -10px;
      `;
    }

    return '';
  }}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0;
      z-index: -1;
      pointer-events: none;
    `}
`;

export const SliderRangeWrapper = styled.div`
  display: flex;
  width: 0;
  margin-left: 0;
  transition: margin-left 0.1s ${({ theme }) => theme.animate.base};
`;

export const RangeItem = styled.div<{ isSelected: boolean; isControlled: boolean }>`
  font-size: ${({ theme }) => theme.textSizes.caption.size};
  line-height: ${({ theme }) => theme.textSizes.caption.lineHeight};
  padding: 4px 10px;
  position: relative;
  min-width: max-content;

  color: ${({ theme }) => theme.palette.main.accent};
  border: 1px solid ${({ theme }) => theme.palette.main.accent};
  border-radius: 100px;

  ${({ theme, isSelected }) =>
    isSelected &&
    css`
      color: ${theme.palette.grey.white};
      background: ${theme.palette.main.accent};
    `}

  ${({ isControlled, isSelected }) =>
    isControlled &&
    !isSelected &&
    css`
      &:hover {
        background: ${({ theme }) => rgba(theme.palette.main.accent, 0.1)};
      }
    `}

    ${({ isControlled }) =>
    isControlled &&
    css`
      cursor: pointer;
    `}

  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.spaces.s};
  }
`;
