import styled from 'styled-components';

interface Props {
  className?: string;
}

const Section: React.FC<Props> = ({ className, children }) => {
  return <section className={className}>{children}</section>;
};

const StyledSection = styled(Section)`
  padding: ${({ theme }) => theme.spaces.s} !important;
  border-radius: ${({ theme }) => theme.shape.borderRadius} !important;
  box-shadow: ${({ theme }) => theme.shadow.base} !important;
`;

export default StyledSection;
