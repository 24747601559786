import { Box } from '@material-ui/core';
import { FC } from 'react';
import { routes } from 'routes';
import { ErrorBox } from '@components/ErrorBox';
import { Button } from '@components/Button';
import Section from '@components/Section';

export const ErrorContainer: FC = () => {
  return (
    <Section>
      <ErrorBox>
        <h3>Произошла ошибка сервера</h3>

        <Box padding="2rem 0" display="flex" alignItems="center" justifyContent="center">
          <Button href={routes.home} variant="outlined">
            На главную
          </Button>
        </Box>
      </ErrorBox>
    </Section>
  );
};
