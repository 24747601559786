import { DateTime } from 'luxon';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { CreateDate, Desc, Poster, Footer, Title, Wrapper } from './styled';
import { routes } from 'routes';
import { ReadMoreLinkContent } from '@components/ReadMoreLink';
import { NewsPage } from '@http/models/api/news';

interface Props {
  newsArticle: NewsPage;
}

export const NewsArticleShort: React.FC<Props> = ({ newsArticle }) => {
  const { title, link, short, publishedDate, sourceUri } = newsArticle;
  const router = useRouter();
  const backPage: string | undefined =
    router.query.backPage &&
    (Array.isArray(router.query.backPage) ? router.query.backPage.toString() : router.query.backPage);
  const href = routes.newsArticle(link ?? '/', backPage || router.asPath);

  return (
    <Link href={href} passHref>
      <Wrapper>
        <div>
          <Title>{title}</Title>
          {sourceUri && <Poster src={sourceUri} alt={title} />}
          {short && <Desc dangerouslySetInnerHTML={{ __html: short }} />}
        </div>
        <Footer>
          <ReadMoreLinkContent />
          <CreateDate>{DateTime.fromISO(publishedDate).toLocaleString()}</CreateDate>
        </Footer>
      </Wrapper>
    </Link>
  );
};
