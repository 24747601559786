import styled from 'styled-components';

export const Wrap = styled.span`
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.link};
  font-weight: 600;

  svg {
    height: 1rem;
    margin-left: ${({ theme }) => theme.spaces.xxs};
  }
`;
