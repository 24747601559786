import styled from 'styled-components';

export const Wrapper = styled.a`
  padding: 1rem;
  box-shadow: ${({ theme }) => theme.shadow.base};
  overflow: auto;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  background: ${({ theme }) => theme.palette.grey.white};
  margin-bottom: ${({ theme }) => theme.spaces.m};
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  border: 1px solid transparent;
  color: ${({ theme }) => theme.palette.text.primary};

  &:hover,
  &:focus-visible {
    opacity: 1;
    border: 1px solid ${({ theme }) => theme.palette.main.primary};
    box-shadow: ${({ theme }) => theme.shadow.large}
    opacity: 1;

    a, a:hover {
      opacity: 1;
    }
`;

export const Title = styled.span`
  display: block;
  font-weight: ${({ theme }) => theme.textSizes.header3.weight};
  font-size: ${({ theme }) => theme.textSizes.header3.size};
  line-height: ${({ theme }) => theme.textSizes.header3.lineHeight};
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: ${({ theme }) => theme.spaces.l};
`;

export const Desc = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CreateDate = styled.p`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const Poster = styled.img`
  width: 100%;
  border-radius: 4px;
  max-width: 320px;

  ${({ theme }) => theme.utils.media.moreThanMobile`
     margin-right: ${({ theme }) => theme.spaces.s};
     width: 40%;
     float: left;
     max-width: 100%;
  `}
`;

export const Footer = styled.div`
  margin-top: ${({ theme }) => theme.spaces.m};
  clear: both;
  display: flex;
  justify-content: space-between;
`;
