import styled from 'styled-components';

export const NewsWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: ${({ theme }) => theme.spaces.s};

  ${({ theme }) => theme.utils.media.tablet`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${({ theme }) => theme.utils.media.screenLessThan1600`
    grid-template-columns: repeat(3, 1fr);
  `}
`;
