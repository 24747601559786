import { httpService } from '@http/service';
import {
  NewsCategory,
  NewsPage,
  NewsPageRecords,
  NewsPageRecordsQuery,
  NewsPageSaveRequest,
  NewsPageCategoryCreate,
  NewsPageCategoryDelete,
} from '@http/models/api/news';
import { HttpOptions } from '@http/service/options';
import { buildQs } from '@http/shared';

export const saveNewsPage = (body: NewsPageSaveRequest) => {
  const options = new HttpOptions().asAuthRoute({ strict: true });
  return httpService.post<number>(`/news`, body, options);
};

export const getNewsListApi = (query: NewsPageRecordsQuery) => {
  const options = new HttpOptions();
  return httpService.get<NewsPageRecords>(buildQs(`/news`, query), options);
};

export const getNewsArticleApi = (slug: string) => {
  const options = new HttpOptions();
  return httpService.get<NewsPage>(`/news/${slug}`, options);
};

export const getNewsCategories = () => {
  const options = new HttpOptions();
  return httpService.get<NewsCategory[]>(`/news/category`, options);
};

export const addNewsCategory = (body: NewsPageCategoryCreate) => {
  const options = new HttpOptions().asAuthRoute({ strict: true });
  return httpService.post<number>(`/news/category`, body, options);
};

export const deleteNewsArticle = (id: number) => {
  const options = new HttpOptions().asAuthRoute({ strict: true });
  return httpService.delete(`/news/${id}`, {}, options);
};

export const deleteNewsCategory = (query: NewsPageCategoryDelete) => {
  const options = new HttpOptions().asAuthRoute({ strict: true });
  return httpService.delete(buildQs(`/news/category`, query), {}, options);
};
