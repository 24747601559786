import { NewsArticleShort } from './NewsArticleShort';
import { NewsWrap } from './styled';
import { NewsPage } from '@http/models/api/news';

interface Props {
  newsList: NewsPage[];
}

export const NewsList: React.FC<Props> = ({ newsList }) => {
  return (
    <NewsWrap>
      {newsList.map(newsArticle => (
        <NewsArticleShort newsArticle={newsArticle} key={newsArticle.id} />
      ))}
    </NewsWrap>
  );
};
