import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import { useWindowWidth } from '@react-hook/window-size';
import { Wrapper, SliderWrapper, SliderRangeWrapper, SliderArrow, RangeItem } from './styled';
import { runOnClient } from '@shared/utils/is-client';
import { requireNotNull } from '@shared/utils/require-not-null';
import { totalBy } from '@shared/utils/helpers';
import { Icon } from '@components/Icon';
import { Nullable } from 'types';
import { NewsCategory } from '@http/models/api/news';

interface ArrowParams {
  enableLeft?: boolean;
  enableRight?: boolean;
}

interface Props {
  categories: NewsCategory[];
  selectedCategory: Nullable<number>;
  hideDefaultCategory?: boolean;
  onSelect?: (cat: Nullable<number>) => void;
}

const slideManualMaxStepPx = 200;
let interval: Nullable<NodeJS.Timeout> = null;

const defaultCategory: NewsCategory = {
  id: 0,
  title: 'Все',
  deletedAt: null,
  createdAt: DateTime.now().toLocaleString(),
  parentId: null,
};

export const NewsTags: FC<Props> = ({ categories, selectedCategory, hideDefaultCategory, onSelect }) => {
  const sliderWrapperRef = useRef<Nullable<HTMLDivElement>>(null);
  const sliderRef = useRef<Nullable<HTMLDivElement>>(null);
  const [arrowParams, setArrowParams] = useState<ArrowParams>({});
  const windowWidth = useWindowWidth();
  const categoriesList = useMemo(() => {
    if (hideDefaultCategory) {
      return categories;
    }
    return [defaultCategory, ...categories];
  }, [categories, hideDefaultCategory]);

  const calculateArrowParams = useCallback(() => {
    if (!sliderWrapperRef.current) return;

    const TAG_MARGIN_RIGHT = 16;

    const tagsNodes = requireNotNull(sliderRef.current).querySelectorAll('[data-news-tag]');
    const sliderWidth =
      totalBy(Array.from(tagsNodes), n => n.getBoundingClientRect().width + TAG_MARGIN_RIGHT) - TAG_MARGIN_RIGHT - 1;
    const { width: wrapperWidth } = sliderWrapperRef.current.getBoundingClientRect();
    const currentScrollOffset = requireNotNull(sliderWrapperRef.current).scrollLeft;

    setArrowParams({
      enableLeft: currentScrollOffset > 0,
      enableRight: wrapperWidth + currentScrollOffset < sliderWidth,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth]);

  useEffect(() => {
    calculateArrowParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollHandler = useCallback(() => {
    calculateArrowParams();
  }, [calculateArrowParams]);

  useEffect(() => {
    if (!sliderWrapperRef.current) return;

    const element = sliderWrapperRef.current;
    runOnClient(() => {
      element.addEventListener('scroll', scrollHandler);
    });

    return () => {
      runOnClient(() => {
        element.removeEventListener('scroll', scrollHandler);
      });
    };
  }, [scrollHandler]);

  const manualNavigate = (direction: 'left' | 'right') => {
    interval && clearInterval(interval);
    let total = slideManualMaxStepPx;
    interval = setInterval(() => {
      if (!sliderWrapperRef.current) {
        return;
      }

      if (total > 0) {
        total -= 10;
        if (direction === 'left') {
          sliderWrapperRef.current.scrollLeft -= 10;
        } else {
          sliderWrapperRef.current.scrollLeft += 10;
        }
      } else {
        interval && clearInterval(interval);
      }
    }, 10);
  };

  const handleNext = () => {
    manualNavigate('right');
  };

  const handlePrev = () => {
    manualNavigate('left');
  };

  const handleSelect = (catId: number) => {
    const selection = catId === 0 ? null : catId;
    onSelect && onSelect(selection);
  };

  const checkSelected = (catId: number) => {
    if (catId === 0 && !selectedCategory) return true;

    return catId === selectedCategory;
  };

  return (
    <Wrapper>
      <SliderArrow isDisabled={!arrowParams.enableLeft} variant="left" onClick={handlePrev}>
        <Icon size="s" name="cheveron-left.outline" />
      </SliderArrow>
      <SliderWrapper ref={sliderWrapperRef}>
        <SliderRangeWrapper ref={sliderRef}>
          {categoriesList.map(cat => (
            <RangeItem
              isSelected={checkSelected(cat.id)}
              isControlled={!!onSelect}
              key={cat.id}
              data-news-tag={cat.id}
              onClick={() => handleSelect(cat.id)}
              tabIndex={0}
            >
              {cat.title}
            </RangeItem>
          ))}
        </SliderRangeWrapper>
      </SliderWrapper>
      <SliderArrow isDisabled={!arrowParams.enableRight} variant="right" onClick={handleNext}>
        <Icon size="s" name="cheveron-right.outline" />
      </SliderArrow>
    </Wrapper>
  );
};
