import { Box, Typography } from '@material-ui/core';

interface Props {
  color?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
}

export const ErrorBox: React.FC<Props> = ({ children, color }) => {
  return (
    <Box padding="4rem 0" display="flex" alignItems="center" justifyContent="center">
      <Typography color={color || 'error'} variant="h6" align="center">
        {children}
      </Typography>
    </Box>
  );
};
